
import Vue from 'vue';
import { AdFormOptions, AdFormPlacementType, AdFormProps } from '@ads/types/AdForm';
import { AdsWindow } from '@ads/types/window';
import adsConfig from '@ads/config/adForm.config';
import AdBlockFallback from '@ads/components/adBlock/AdBlockFallback.vue';
import adxTag, { getAdId, initAdx, isMobileOrTablet, removeLoadingClass } from '@ads/utils/adx';
import ibbTag, { initDiMatter } from '@ads/utils/ibb';

interface Data {
  divId: string;
  adFormType: AdFormPlacementType | null;
  isMounted: boolean;
  hasBeenRendered: boolean;
  isFallback?: boolean;
  adBlockFallback?: AdFormOptions['adBlockFallback'];
  observer: MutationObserver | null;
}

interface Computed {
  classModifier: Record<string, unknown>;
  disableAd: boolean;
  isMobileOrTablet: boolean;
  disabledAdsList: string[];
  adRenderingState: boolean;
  idModifier: string | null;
  isAdBlocked?: boolean;
  isAdFree: boolean;
  isCustomer: boolean;
  hasSettings: boolean;
}

interface Props {
  adType: string;
  uniqueIdentifier: string;
  settings: AdFormOptions;
}

interface AdFormType {
  type: AdFormPlacementType | null;
  isFallback: boolean;
}

interface Methods {
  adProperties: (placements: Record<string, AdFormOptions>) => AdFormProps;
  initBanner: () => Promise<void>;
  getAdFormType: (adType: Props['adType']) => AdFormType;
  placeAd: (adProps: AdFormProps) => void;
  placeOutstreamAd: (placement: string, adProps: AdFormProps) => void;
  parseDiMatterAd: () => string[] | boolean;
  removeLoadingClass: typeof removeLoadingClass;
  setupCommentAds: () => void;
}
declare const window: AdsWindow;

const mobileAds = adsConfig.mobileAds as AdFormPlacementType[];
const mobileFallbackAds = adsConfig.mobileFallbackAds as Record<string, AdFormPlacementType>;

export default Vue.extend<Data, Methods, Computed, Props>({
  name: 'AdFormBanner',
  components: {
    AdBlockFallback,
  },
  props: {
    adType: {
      type: String,
      default: '',
      required: false,
    },
    uniqueIdentifier: {
      type: String,
      default: '',
      required: false,
    },
    settings: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  data() {
    return {
      divId: '',
      adFormType: null,
      isMounted: false,
      hasBeenRendered: false,
      isFallback: false,
      adBlockFallback: undefined,
      observer: null,
    };
  },
  computed: {
    isMobileOrTablet() {
      return isMobileOrTablet();
    },
    disableAd() {
      return this.disabledAdsList?.includes(this.adType);
    },
    disabledAdsList() {
      return this.$store?.getters['adForm/getDisabledAdsList'];
    },
    classModifier() {
      return { [`banner--${this.adFormType}`]: this.adFormType };
    },
    adRenderingState() {
      return this.$store?.getters['adForm/setAdsRenderingState'];
    },
    idModifier() {
      let id = null;

      if (this.adFormType === 'header') {
        id = 'header-top-banner';
      }

      return id;
    },
    isAdBlocked() {
      return this.$store?.getters['adForm/getAdBlockLayerState'];
    },
    isAdFree() {
      return this.$store?.getters['adForm/getIsAdFree'];
    },
    isCustomer() {
      return this.$store?.getters['adForm/getIsCustomer'];
    },
    hasSettings() {
      return this.$store?.getters['adForm/getHasSettings'];
    },
  },
  watch: {
    async adRenderingState(canRender) {
      if (canRender && !this.$isServer) {
        await this.initBanner();
      }
    },
    async disableAd(isDisabled, wasDisabled) {
      // Trigger this check after user with subscriber/adFree account logs out
      if (!isDisabled && wasDisabled && !this.hasBeenRendered) {
        await this.$nextTick();
        await this.initBanner();
      }
    },
    async hasSettings(value, oldValue) {
      if (value !== oldValue) {
        await this.$nextTick();
        await this.initBanner();
      }
    },
    async isAdFree(value, oldValue) {
      if (value !== oldValue) {
        initAdx({ isAdFree: this.isAdFree, isCustomer: this.isCustomer });
      }
    },
    async isCustomer(value, oldValue) {
      if (value !== oldValue) {
        initAdx({ isAdFree: this.isAdFree, isCustomer: this.isCustomer });
      }
    },
  },
  methods: {
    adProperties(placements) {
      const keywords: string[] = this.$store?.getters['adForm/getAdsKeywords'];
      const keyValues: string[] = this.$store?.getters['adForm/getAdsKeyValues'];
      const settingsMkw = this.settings.mkw || [];
      const settingsMkv = this.settings.mkv || [];
      const mark = this.settings.mark || false;
      const isApp = this.$store?.getters['adForm/getIsApp'];
      const isLoggedIn = this.$store?.getters['adForm/getIsLoggedIn'];

      const adProps: AdFormProps = {
        dom: this.settings.divId || this.divId,
        ...placements[this.adFormType as AdFormPlacementType], // Channel config ads section placements settings
        ...this.settings, // Prop placements settings
        mkw: [...keywords, ...settingsMkw],
        mkv: [...keyValues, ...settingsMkv],
        mark,
        lazyload: this.settings.lazyload || false,
        isAdFree: this.isAdFree,
        isApp,
        isCustomer: this.isCustomer,
        isLoggedIn,
        group: this.settings.group || [],
      };

      return adProps;
    },
    async initBanner() {
      const adFormType = this.getAdFormType(this.adType);
      this.adFormType = adFormType.type;
      this.isFallback = adFormType.isFallback;

      if (typeof window.adformtag === 'undefined') {
        window.adformtag = [];
      }

      if (this.disableAd || !this.adRenderingState || this.hasBeenRendered || !this.hasSettings) {
        // Remove loading class
        removeLoadingClass(this.settings.divId || this.divId || 'adx-' + this.settings.mid);

        return;
      }

      if (!this.isMobileOrTablet && !this.isFallback && mobileAds.find((ad) => ad === this.adFormType)) {
        // Remove loading class
        removeLoadingClass(this.settings.divId || this.divId || 'adx-' + this.settings.mid);

        return;
      }

      if (!this.adFormType && !this.isFallback) {
        // Remove loading class
        removeLoadingClass(this.settings.divId || this.divId || 'adx-' + this.settings.mid);

        return;
      }

      const placements: Record<string, AdFormOptions> = this.$store?.getters['adForm/getAdsPlacements'];
      const adProps: AdFormProps = this.adProperties(placements || {});

      if (!window.adxadid) {
        window.adxadid = getAdId();
        window.adformtag.push(function () {
          window.adformtag.setRequestParam('adid', window.adxadid);
        });
      }

      // Export mkv and mkw values to window for check-ups
      window.d2_adx_mkv = window.adx_mkv = adProps.mkv || [];
      window.d2_adx_mkw = window.adx_mkw = adProps.mkw || [];

      // Parse in keys for dimatter
      if (adProps.mkw?.length) {
        const _attachData = window.dimatter.find((v: string[]) => v[0] === '_attachData') || null;
        let dimatter_keys: string[] = [];

        const pathParts = window.location.pathname.split('/');

        // Remove last element if empty
        if (pathParts[pathParts.length - 1] === '') {
          pathParts.pop();
        }

        pathParts.shift();
        dimatter_keys = dimatter_keys.concat(pathParts);

        for (let i = 0; i < adProps.mkw.length; i++) {
          if (adProps.mkw[i].indexOf('ck-') === 0) {
            dimatter_keys.push(adProps.mkw[i].substring(3).replace(/-/g, ' '));
          }

          if (adProps.mkw[i].indexOf('cid-') === 0) {
            dimatter_keys.push(adProps.mkw[i]);
          }
        }

        // Remove duplicates
        dimatter_keys = [...new Set(dimatter_keys)];

        if (dimatter_keys.length && typeof window.dimatter !== 'undefined' && !_attachData) {
          // Push in new _attachData value
          window.dimatter.push(['_attachData', 'ck-keys', dimatter_keys]);
        } else {
          // Replace existing _attachData value
          const _attachDataIndex = window.dimatter.findIndex((v: string[]) => v[0] === '_attachData');
          window.dimatter[_attachDataIndex] = ['_attachData', 'ck-keys', dimatter_keys];
        }
      }

      this.placeAd(adProps);
      this.hasBeenRendered = true;
    },
    async placeAd(adProps) {
      const placement = this.isFallback && adProps.fallback ? adProps.fallback : adProps.mid || '';
      if (placement === '') {
        return;
      }

      // Render Adform placement
      const options = {
        dom: adProps.dom,
        mark: adProps.mark,
        keys: adProps.mkw,
        kv: adProps.mkv,
        lazy: adProps.lazyload,
        isApp: adProps.isApp,
        isCustomer: adProps.isCustomer,
        group: adProps.group,
      };

      // Detect DigitalMatter ad placement and parse its properties
      const dimatter = this.parseDiMatterAd();
      if (dimatter) {
        this.divId = (Array.isArray(dimatter) ? dimatter[0] : dimatter) as string;
        await this.$nextTick();
        ibbTag(this.divId, { ...options, dom: this.divId });
        return;
      }

      // Reset targeting
      if (typeof window.adformtag.resetTargeting === 'function') {
        window.adformtag.resetTargeting(placement);
      }

      // Outstream ad placement between 1st and 2nd square positions
      const placements: Record<string, AdFormOptions> = this.$store?.getters['adForm/getAdsPlacements'];
      const outstreamPlacement = placements.outstream && placements.outstream.mid && placements.outstream.mid[0];
      const index = this.settings.index;
      if (!this.isCustomer && !this.isMobileOrTablet && outstreamPlacement && this.adType === 'square' && index === 0) {
        this.placeOutstreamAd(outstreamPlacement, adProps);
      }

      adxTag(placement, options);
    },
    placeOutstreamAd(placement, adProps) {
      const script = window.document.createElement('script');
      script.src = '//s1.adform.net/banners/scripts/video/outstream/inview.js';
      script.dataset.pmpId = `${placement}&mkv=${adProps.mkv}&mkw=${adProps.mkw}`;
      script.dataset.soundOnhover = 'false';

      const div = window.document.createElement('div');
      div.id = 'adform-outstream';
      div.style.height = '0px';
      div.style.overflow = 'hidden';
      div.appendChild(script);

      const dom = window.document.getElementById(adProps.dom || this.divId) as HTMLElement;
      const target = dom.parentElement!.previousElementSibling?.previousElementSibling as HTMLElement;
      target.before(div);
    },
    getAdFormType(adType) {
      const fallBackAdFormType = mobileFallbackAds[adType] || mobileAds.find((ad) => ad === adType) || null;

      return {
        type: this.isMobileOrTablet ? fallBackAdFormType : (adType as AdFormPlacementType),
        isFallback: this.isMobileOrTablet && fallBackAdFormType !== null,
      };
    },
    parseDiMatterAd() {
      let placement = this.settings.mid || false;

      if (this.isMobileOrTablet && this.settings.fallback) {
        placement = this.settings.fallback;
      }

      if (placement && /DIM:/i.test(placement)) {
        return placement.replace(/DIM:/i, '').split(',');
      }

      return false;
    },
    removeLoadingClass,
    setupCommentAds() {
      if (/^(.+-[\d]{3,}\/(diskusija|komentarii))$/i.test(location.pathname) && !this.isAdFree && !window.setupCommentAdsDone) {
        window.addEventListener('requestCommentEmbed', function (event: any) {
          const d = document.getElementById(event.detail.id) as HTMLElement;
          const did = d.id + 'i';
          const i = event.detail.iteration;
          const slot = isMobileOrTablet() ? 'DLT_Mobile_300x250_' : 'DLT_Comments_300x250_';

          function pad(i: number) {
            return i > 9 ? String(i) : `0${i}`;
          }

          function dom(i?: string, twin?: boolean) {
            const id = i || did;

            if (twin) {
              d.innerHTML =
                '<div id="' +
                id +
                '--left" style="float:left;width:300px;margin-left:60px;margin-bottom:20px;overflow:hidden"></div><div id="' +
                id +
                '--right" style="float:right;width:300px;margin-right:60px;margin-bottom:20px;overflow:hidden"></div>';
            } else {
              d.innerHTML = '<div id="' + id + '" style="width:300px;margin: 0 auto 20px auto;overflow:hidden"></div>';
            }
          }

          switch (i) {
            case 1:
              dom();
              adxTag(isMobileOrTablet() ? '457213' : '522315', { mark: 1, dom: did });
              break;
            case 2:
              dom();
              adxTag(isMobileOrTablet() ? '457214' : '525394', { mark: 1, dom: did });
              break;
            default:
              if (isMobileOrTablet()) {
                dom(slot + i);
                ibbTag(slot + i, { mark: 1 });
              } else {
                dom(slot + pad(i), true);
                ibbTag(slot + pad(i) + '--left', { mark: 1 });
                ibbTag(slot + pad(i) + '--right', { mark: 1 });
              }
              break;
          }
        });

        window.setupCommentAdsDone = true;
      }
    },
  },
  created() {
    // Set adFormType before mount
    const adFormType = this.getAdFormType(this.adType);
    this.adFormType = adFormType.type;
  },
  async mounted() {
    if (this.isAdBlocked && !this.isCustomer && !this.isAdFree) {
      this.adBlockFallback = this.settings.adBlockFallback;
    }

    const isApp = this.$store?.getters['adForm/getIsApp'];

    // Initialize AdForm
    initAdx({ isAdFree: this.isAdFree, isCustomer: this.isCustomer });

    // Setup Comment ads injection only after Piano state is known
    if (this.$store?.state?.piano?.isScriptInited) {
      this.setupCommentAds();
    } else if (!this.$store?.state?.piano?.isScriptInited) {
      this.$store?.watch(
        (state) => state.piano.isScriptInited,
        async () => {
          this.setupCommentAds();
        }
      );
    }

    // Initialise DiMatter
    initDiMatter({ isAdFree: this.isAdFree, isApp });

    if (!this.divId) {
      const dimatter = this.parseDiMatterAd();
      if (dimatter) {
        this.divId = (Array.isArray(dimatter) ? dimatter[0] : dimatter) as string;
      } else {
        const rnd = Math.floor(10000 + Math.random() * 90000);
        this.divId = this.settings.divId ? this.settings.divId : `ad-${rnd}`;
      }

      await this.$nextTick();
    }

    await this.initBanner();

    if (this.adFormType === 'scroller') {
      const scroller = this.$refs[`${this.adFormType}-${this.divId}`] as HTMLDivElement;
      if (scroller) {
        this.observer = new MutationObserver((mutations: any) => {
          this.$nextTick(() => {
            const mutation = mutations[0] as MutationRecord;
            const nodes = mutation.addedNodes as NodeList;
            const node = nodes[0];
            if (node && node.nodeName === 'ADFM-AD') {
              const element = node as HTMLElement;
              const iframe = element.querySelector('iframe') as HTMLIFrameElement;
              const iframeBody = iframe.contentDocument as any;
              new MutationObserver((mutations: any) => {
                this.$nextTick(() => {
                  const mutation = mutations[0] as MutationRecord;
                  if (mutation.removedNodes.length) {
                    const nodes = mutation.removedNodes as NodeList;
                    const node = nodes[0] as HTMLElement;
                    if (node.id === 'adf-banner') {
                      scroller.style.display = 'none';
                    }
                  }
                });
              }).observe(iframeBody, { subtree: true, childList: true });
            }
          });
        });

        this.observer.observe(scroller, { subtree: true, childList: true });
      }
    }
  },
  beforeDestroy() {
    if (this.adFormType === 'mobileinterstitial') {
      window.document.querySelector('html')!.classList.remove('adfm-fullscreen', 'adfm-fullscreen-touch', 'adfm-zindex-auto');
    }

    if (this.observer) {
      this.observer.disconnect();
    }
  },
});
