import jwplayer from '@media/types/JwPlayer';
import { AudioItem } from '../../types/getAudio';
import { VideoItem } from '../../types/getVideo';

import { cXense } from '@analytics/trackers';
import { loadScript } from '../loadScript';
import PlayerAnalytics from './BaseAnalytics';

import config from '../../config/media.config';

interface cXWindow extends Window {
  [index: string]: any;
}

interface EventParameters {
  video_title: string;
  video_big_screen_value: string;
  video_play_time?: string;
  video_play_percents?: string;
  mid?: string;
}

declare let window: cXWindow;

class cXenseTracker extends PlayerAnalytics {
  private _cX!: cXense;
  private _options = { origin: config.analytics.cXense.origin, persistedQueryId: config.analytics.cXense.persistedQueryId };

  protected _eventMap = {
    play: 'video_start',
    pause: 'video_pause',
    idle: 'video_stop',
    complete: 'video_complete',
    seek: 'media_seek',
    fullscreen: 'media_fullscreen',
    play10: 'video_progress_10',
    play30: 'video_progress_30',
    keepAlive: 'keep_alive_ping',
    secondsPlayed: 'video_complete',
    percentsPlayed: 'percents_played',
    // ad events
    adImpression: 'video_ad_impression',
    adRequest: 'video_ad_request',
  };

  constructor(player: jwplayer.JWPlayer, mediaData: AudioItem | VideoItem, lsa: boolean) {
    super(player, mediaData, lsa);
  }

  async initAnalytics() {
    if (!window.cX) {
      loadScript({ script: '//cdn.cxense.com/cx.js', attr: { async: 'async' } }).then(async () => {
        this._cX = new cXense();
        await this._cX.init({ uid: config.analytics.cXense.siteId });
      });
    } else {
      this._cX = new cXense();
      await this._cX.init({ uid: config.analytics.cXense.siteId });
    }
    this.setEventsListeners();
  }

  public sendEvent(eventName: string, optionalData: Record<string, unknown>) {
    const isBigScreen = optionalData && optionalData.lsa ? 'false' : 'true';
    const parameters: EventParameters = {
      video_title: this.gaTitle(this._mediaData),
      video_big_screen_value: isBigScreen,
    };

    if (optionalData?.time) {
      parameters.video_play_time = optionalData.time as string;
    }
    if (optionalData?.percent) {
      parameters.video_play_percents = optionalData.percent as string;
    }
    if (optionalData?.mid) {
      parameters.mid = optionalData.mid as string;
    }

    this._cX.sendEvent(eventName, parameters, this._options);
  }
}

export { cXenseTracker };
