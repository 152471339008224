interface Icon {
  color?: 'blue' | 'dark-blue' | 'dark-gray' | 'gray' | 'red' | 'tan' | 'green' | 'yellow' | 'sand';
  position: 'bottom' | 'left' | 'right';
  type: 'text' | 'fa' | 'default';
  weight: number;
  fa?: boolean;
}

// Icons config
// N.B. Sorted alphabetically!
export const iconsConfig: Record<string, Icon> = {
  audio: {
    position: 'right',
    type: 'default',
    weight: 1,
  },
  awkwardQuestions: {
    color: 'dark-blue',
    type: 'text',
    position: 'bottom',
    weight: 1,
  },
  breakingNews: {
    color: 'red',
    position: 'bottom',
    type: 'text',
    weight: 3,
  },
  career: {
    color: 'tan',
    position: 'bottom',
    type: 'text',
    weight: 1,
  },
  detector: {
    color: 'blue',
    position: 'bottom',
    type: 'text',
    weight: 1,
  },
  detectorCategory: {
    position: 'right',
    type: 'text',
    weight: 1,
  },
  economist: {
    position: 'left',
    type: 'default',
    weight: 1,
  },
  everydayHeroes: {
    color: 'dark-blue',
    position: 'bottom',
    type: 'text',
    weight: 1,
  },
  exclusive: {
    color: 'blue',
    position: 'bottom',
    type: 'text',
    weight: 1,
  },
  gallery: {
    position: 'right',
    type: 'default',
    weight: 1,
  },
  graph: {
    position: 'right',
    type: 'default',
    fa: true,
    weight: 1,
  },
  important: {
    color: 'blue',
    position: 'bottom',
    type: 'text',
    weight: 3,
  },
  interview: {
    color: 'blue',
    position: 'bottom',
    type: 'text',
    weight: 1,
  },
  invest: {
    position: 'left',
    type: 'text',
    weight: 1,
  },
  liveBlog: {
    position: 'right',
    type: 'default',
    fa: true,
    weight: 1,
  },
  premiere: {
    color: 'blue',
    position: 'bottom',
    type: 'text',
    weight: 1,
  },
  pressRelease: {
    color: 'gray',
    position: 'bottom',
    type: 'text',
    weight: 2,
  },
  reminds: {
    color: 'blue',
    position: 'bottom',
    type: 'text',
    weight: 2,
  },
  renew: {
    color: 'blue',
    position: 'bottom',
    type: 'text',
    weight: 2,
  },
  research: {
    color: 'blue',
    position: 'bottom',
    type: 'text',
    weight: 1,
  },
  saugu: {
    position: 'left',
    type: 'default',
    weight: 1,
  },
  sauguComment: {
    color: 'green',
    position: 'bottom',
    type: 'text',
    weight: 1,
  },
  regionNews: {
    color: 'yellow',
    position: 'bottom',
    type: 'text',
    weight: 1,
  },
  dakaras: {
    color: 'sand',
    position: 'bottom',
    type: 'text',
    weight: 1,
  },
  weekReview: {
    color: 'blue',
    position: 'bottom',
    type: 'text',
    weight: 1,
  },
  specialProject: {
    color: 'blue',
    position: 'bottom',
    type: 'text',
    weight: 1,
  },
  stream: {
    color: 'red',
    position: 'bottom',
    type: 'text',
    weight: 4,
  },
  streamDvr: {
    color: 'red',
    position: 'bottom',
    type: 'text',
    weight: 4,
  },
  streamSoon: {
    color: 'blue',
    position: 'bottom',
    type: 'text',
    weight: 4,
  },
  test: {
    color: 'blue',
    position: 'bottom',
    type: 'text',
    weight: 1,
  },
  video: {
    position: 'right',
    type: 'default',
    weight: 1,
  },
};

// Icon name => SVG icon mapping
export const iconList = {
  audio: 'microphone',
  gallery: 'gallery',
  trending: 'trending',
  video: 'play',
  saugu: 'saugu',
};

// Career channel used icon from Delfi 2.0 CMS
export const careerIcons = [
  'frontPageMostPopularJobOffers',
  'frontPageLookingForTalents',
  'frontPageInterviewWithCLevelManagers',
  'frontPageRelevantInTheOffice',
  'frontPageItsInteresting',
  'frontPageHHRPerspective',
];

// Career channel used icons from Delfi 1.0 CMS
export const careerTags = [89580921, 89580923, 89580941, 89580979, 89580981, 89969303];

// Lie detector project categories
export const detectorCategories = [
  85827865, 79451385, 79451419, 85827867, 88121525, 88122145, 92718855, 92719795, 93953369, 93953457, 93481599, 92782775, 85827871, 79451335, 96049257,
];

// Political Ads articles categories
export const politicalAdCategories = [84666519, 85038351, 80167949, 91841171];
