export default {
  // mobile generic ads
  '457213': 'DLT_Mobile_300x250_1',
  '457214': 'DLT_Mobile_300x250_2',
  '457215': 'DLT_Mobile_300x250_3',
  '457216': 'DLT_Mobile_300x250_4',
  '457217': 'DLT_Mobile_300x250_5',
  '457218': 'DLT_Mobile_300x250_6',
  '457219': 'DLT_Mobile_300x250_7',

  // mobile generic ads - crime section exceptions
  '457213-kriminalai': 'DLT_Kriminalai_Mobile_300x250_01',
  '457214-kriminalai': 'DLT_Kriminalai_Mobile_300x250_02',
  '457215-kriminalai': 'DLT_Kriminalai_Mobile_300x250_03',
  '457216-kriminalai': 'DLT_Kriminalai_Mobile_300x250_04',
  '457217-kriminalai': 'DLT_Kriminalai_Mobile_300x250_05',
  '457218-kriminalai': 'DLT_Kriminalai_Mobile_300x250_06',
  '457219-kriminalai': 'DLT_Kriminalai_Mobile_300x250_07',

  // mobile generic ads - boredpanda section exceptions
  '457213-boredpanda': 'DLT_Boredpanda_Mobile_300x250_01',
  '457214-boredpanda': 'DLT_Boredpanda_Mobile_300x250_02',
  '457215-boredpanda': 'DLT_Boredpanda_Mobile_300x250_03',

  // mobile generic ads - kablys section exceptions
  '457213-kablys': 'DKB06_Mobile_300x250_1',
  '457214-kablys': 'DKB07_Mobile_300x250_2',
  '457215-kablys': 'DKB08_Mobile_300x250_3',
  '457216-kablys': 'DKB09_Mobile_300x250_4',
  '457217-kablys': 'DKB10_Mobile_300x250_5',

  // mobile front page ads
  '591552': 'DLT_Titulinis_Mobile_300x250_Agro',
  '633898': 'DLT_Titulinis_Mobile_300x250_Kriminalai',
  '591559': 'DLT_Titulinis_Mobile_300x250_Mokslas',
  '591554': 'DLT_Titulinis_Mobile_300x250_Moterys',
  '591570': 'DLT_Titulinis_Mobile_300x250_Orai',
  '591561': 'DLT_Titulinis_Mobile_300x250_Sportas',
  '591549': 'DLT_Titulinis_Mobile_300x250_Sveikata',
  '591555': 'DLT_Titulinis_Mobile_300x250_Vaikai',
  '591565': 'DLT_Titulinis_Mobile_300x250_Veidai',
  '598413': 'DLT_Boredpanda_Mobile_300x250_Titulinis',
  '591557': 'DLT_Titulinis_Mobile_300x250_Fotogalerijos',
  '591550': 'DLT_Titulinis_Mobile_300x250_special--verslas',
  '591553': 'DLT_Titulinis_Mobile_300x250_special--bustas',
  '591556': 'DLT_Titulinis_Mobile_300x250_special--maistas',
  '591558': 'DLT_Titulinis_Mobile_300x250_special--stilius',
  '591560': 'DLT_Titulinis_Mobile_300x250_special--auto',
  '591562': 'DLT_Titulinis_Mobile_300x250_special--keliones',
  '591569': 'DLT_Titulinis_Mobile_300x250_special--gyvenimas',
  '609561': 'DLT_Titulinis_Mobile_300x250_special--darbas',
  '618927': 'DLT_Titulinis_Mobile_300x250_special--bloomberg',
  '623492': 'DLT_Titulinis_Mobile_300x250_special--miestai',
  '633377': 'DLT_Titulinis_Mobile_300x250_special--tvtransliacijos',
  '637293': 'DLT_Titulinis_Mobile_300x250_special--delfitv',
  '658586': 'DLT_Titulinis_Mobile_300x250_special--aktualijos',
  '676044': 'DLT_Titulinis_Mobile_300x250_special--kablys',
  '696623': 'DLT_Titulinis_Mobile_300x250_special--atsakingaspoziuris',
  '736353': 'DLT_Titulinis_Mobile_300x250_special--pilietis',
  '764225': 'DLT_Titulinis_Mobile_300x250_special--grynas',
  '833276': 'DLT_Titulinis_Mobile_300x250_special--kultura',
  '653705': 'DLT_Titulinis_Mobile_300x250_special--letena',
  '921542': 'DLT_Titulinis_Mobile_300x250_special--receptai',
  '619439': 'DLT_Titulinis_Mobile_300x250_special--pt',

  '470752': 'DLT_Titulinis_300x250_SEB',
  '1874144': 'DLT_Titulinis_Mobile_300x250_1', // 01. Mobile 300x250 (1) Titulinis
  '1555144': 'DLT_Titulinis_Mobile_300x250_2', // 02. Mobile 300x250 (2) Titulinis
  '1874146': 'DLT_Titulinis_Mobile_300x250_3', // 03. Mobile 300x250 (3) Titulinis
  '1874145': 'DLT_Titulinis_Mobile_300x250_4', // 04. Mobile 300x250 (4) Titulinis
  '1874147': 'DLT_Titulinis_Mobile_300x250_5', // 05. Mobile 300x250 (5) Titulinis
  '1874148': 'DLT_Titulinis_Mobile_300x250_6', // 06. Mobile 300x250 (6) Titulinis
  '1874149': 'DLT_Titulinis_Mobile_300x250_7', // 07. Mobile 300x250 (7) Titulinis

  // desktop visa aplinka - aktualijos
  '521402': 'DLT_300x250_InArticle_1',
  '521403': 'DLT_300x250_InArticle_2',
  '564889': 'DLT_300x250_InArticle_3',
  '567246': 'DLT_300x250_InArticle_4',

  // desktop visa aplinka - verslas
  '521409': 'DLT_300x250_InArticle_1',
  '521410': 'DLT_300x250_InArticle_2',
  '564891': 'DLT_300x250_InArticle_3',
  '567247': 'DLT_300x250_InArticle_4',

  // desktop visa aplinka - mokslas
  '521416': 'DLT_300x250_InArticle_1',
  '521417': 'DLT_300x250_InArticle_2',
  '564892': 'DLT_300x250_InArticle_3',

  // desktop visa aplinka - auto
  '521446': 'DLT_300x250_InArticle_1',
  '521447': 'DLT_300x250_InArticle_2',
  '564894': 'DLT_300x250_InArticle_3',

  // desktop visa aplinka - sportas
  '521457': 'DLT_300x250_InArticle_1',
  '521458': 'DLT_300x250_InArticle_2',
  '564896': 'DLT_300x250_InArticle_3',
  '567248': 'DLT_300x250_InArticle_4',

  // desktop visa aplinka - veidai
  '521465': 'DLT_300x250_InArticle_1',
  '521466': 'DLT_300x250_InArticle_2',
  '564898': 'DLT_300x250_InArticle_3',
  '567249': 'DLT_300x250_InArticle_4',

  // desktop visa aplinka - gyvenimas
  '521475': 'DLT_300x250_InArticle_1',
  '521476': 'DLT_300x250_InArticle_2',
  '564900': 'DLT_300x250_InArticle_3',
  '567250': 'DLT_300x250_InArticle_4',

  // desktop visa aplinka - pilietis
  '521489': 'DLT_300x250_InArticle_1',
  '521490': 'DLT_300x250_InArticle_2',
  '564902': 'DLT_300x250_InArticle_3',

  // desktop visa aplinka - grynas
  '521517': 'DLT_Pixel',
  '521511': 'DLT_1200x250',
  '521513': 'DLT_300x600_01',
  '521514': 'DLT_300x600_02',
  '521515': 'DLT_300x250_InArticle_1',
  '521516': 'DLT_300x250_InArticle_2',

  // desktop visa aplinka - sveikata
  '521533': 'DLT_300x250_InArticle_1',
  '521534': 'DLT_300x250_InArticle_2',

  // desktop visa aplinka - maistas
  '521543': 'DLT_Pixel',
  '521537': 'DLT_1200x250',
  '521539': 'DLT_300x600_01',
  '521540': 'DLT_300x600_02',
  '521541': 'DLT_300x250_InArticle_1',
  '521542': 'DLT_300x250_InArticle_2',

  // desktop visa aplinka - agro
  '521553': 'DLT_300x250_InArticle_1',
  '521554': 'DLT_300x250_InArticle_2',

  // desktop visa aplinka - bustas
  '521566': 'DLT_Pixel',
  '521560': 'DLT_1200x250',
  '521562': 'DLT_300x600_01',
  '521563': 'DLT_300x600_02',
  '544161': 'DLT_300x600_03',
  '633719': 'DLT_300x600_04',
  '521564': 'DLT_300x250_InArticle_1',
  '521565': 'DLT_300x250_InArticle_2',
  '556682': 'DLT_300x250_InArticle_3',

  // desktop visa aplinka - stilius
  '521579': 'DLT_Pixel',
  '521573': 'DLT_1200x250',
  '521575': 'DLT_300x600_01',
  '521576': 'DLT_300x600_02',
  '521577': 'DLT_300x250_InArticle_1',
  '521578': 'DLT_300x250_InArticle_2',

  // desktop visa aplinka - keliones
  '521588': 'DLT_Pixel',
  '521581': 'DLT_1200x250',
  '521584': 'DLT_300x600_01',
  '521585': 'DLT_300x600_02',
  '545292': 'DLT_300x600_03',
  '633718': 'DLT_300x600_04',
  '521586': 'DLT_300x250_InArticle_1',
  '521587': 'DLT_300x250_InArticle_2',
  '597713': 'DLT_300x250_InArticle_3',

  // desktop delfi.lt ru
  '522135': 'DLT_RU_Pixel',
  '522129': 'DLT_RU_1200x250',
  '522130': 'DLT_300x600_Kampas',
  '522131': 'DLT_RU_300x600_01',
  '522132': 'DLT_RU_300x600_02',
  '522138': 'DLT_RU_300x600_03',
  '522139': 'DLT_RU_300x600_04',
  '522140': 'DLT_RU_880x250_01',
  '522141': 'DLT_RU_880x250_02',
  '522133': 'DLT_RU_300x250_InArticle_01',
  '522134': 'DLT_RU_300x250_InArticle_02',
  '564903': 'DLT_RU_300x250_InArticle_03',

  // desktop visa aplinka - moterys
  '526190': 'DLT_Pixel',
  '526184': 'DLT_1200x250',
  '526186': 'DLT_300x600_01',
  '526187': 'DLT_300x600_02',
  '526192': 'DLT_300x600_03',
  '529549': 'DLT_300x600_04',
  '526188': 'DLT_300x250_InArticle_1',
  '526189': 'DLT_300x250_InArticle_2',

  // desktop visa aplinka - vaikai
  '538069': 'DLT_Pixel',
  '538060': 'DLT_1200x250',
  '538063': 'DLT_300x600_01',
  '538064': 'DLT_300x600_02',
  '541277': 'DLT_300x600_03',
  '680331': 'DLT_300x600_04',
  '538065': 'DLT_300x250_InArticle_1',
  '538066': 'DLT_300x250_InArticle_2',

  // desktop visa aplinka - darbas
  '609556': 'DLT_Pixel',
  '609552': 'DLT_1200x250',
  '609554': 'DLT_300x600_01',
  '609557': 'DLT_300x600_02',
  '609555': 'DLT_300x250_InArticle_1',
  '609558': 'DLT_300x250_InArticle_2',

  // desktop visa aplinka - kultura
  '644623': 'DLT_300x250_InArticle_1',
  '644624': 'DLT_300x250_InArticle_2',

  // desktop visa aplinka - miestai
  '619600': 'DLT_Pixel',
  '619594': 'DLT_1200x250',
  '619596': 'DLT_300x600_01',
  '619597': 'DLT_300x600_02',
  '619601': 'DLT_300x600_03',
  '619598': 'DLT_300x250_InArticle_1',
  '619599': 'DLT_300x250_InArticle_2',
  '619602': 'DLT_300x250_InArticle_3',

  // desktop kriminalai
  '521402-kriminalai': 'DLT_Kriminalai_300x250_InArticle_01',
  '521403-kriminalai': 'DLT_Kriminalai_300x250_InArticle_02',
  '564889-kriminalai': 'DLT_Kriminalai_300x250_InArticle_03',
  '567246-kriminalai': 'DLT_Kriminalai_300x250_InArticle_04',

  // desktop delfi.lt boredpanda
  '581218-boredpanda': 'DLT_Boredpanda_300x250_InArticle_01',
  '581221-boredpanda': 'DLT_Boredpanda_300x250_InArticle_02',

  // comment ads
  '522315': 'DLT_Comments_300x250_01',
  '525394': 'DLT_Comments_300x250_02',

  // desktop delfi.lt titulinis
  '521593': 'DLT_Titulinis_1200x250',
  '521595': 'DLT_Titulinis_300x600_01',
  '521596': 'DLT_Titulinis_300x600_02',
  '521597': 'DLT_Titulinis_300x600_03',
  '521599': 'DLT_Titulinis_300x600_04',
  '521607': 'DLT_Titulinis_300x600_05',
  '803661': 'DLT_Titulinis_300x600_06',
  '974075': 'DLT_Titulinis_300x600_07',
  '907244': 'DLT_Titulinis_300x600_08',
  '1375881': 'DLT_Titulinis_300x600_09',
  // '1429772': 'DLT_Titulinis_300x600_10',
  // 08. Titulinis 300x600 (6) (perkatonas)

  // desktop delfi.lt titulinis aktualijos
  '521831': 'DLT_Titulinis_Aktualijos_infoblokas_300x600_04',
  '530463': 'DLT_Titulinis_Aktualijos_infoblokas_300x600_05',
  '556240': 'DLT_Titulinis_Aktualijos_infoblokas_300x600_06',

  // desktop delfi.lt titulinis delfitv
  '521816': 'DLT_Titulinis_1200x250_Generic--delfitv',
  '521822': 'DLT_Titulinis_300x600_01_Generic--delfitv',

  // desktop delfi.lt titulinis vasara
  '825451': 'DLT_Titulinis_1200x250_Generic--vasara',
  '825459': 'DLT_Titulinis_300x600_01_Generic--vasara',

  // desktop delfi.lt titulinis atgal i gyvenima
  '814935': 'DLT_Titulinis_1200x250_Generic--igyvenima',

  // desktop delfi.lt titulinis uzsienyje
  '521957': 'DLT_Titulinis_1200x250_Generic--uzsienyje',
  '521958': 'DLT_Titulinis_300x600_01_Generic--uzsienyje',

  // desktop delfi.lt titulinis atsakingas poziuris
  '800156': 'DLT_Titulinis_1200x250_Generic--atsakingaspoziuris',
  '800157': 'DLT_Titulinis_300x600_01_Generic--atsakingaspoziuris',

  // desktop delfi.lt titulinis pagaminta lietuvoje
  '837726': 'DLT_Titulinis_300x250_01_Generic--pagamintalietuvoje',

  // desktop delfi.lt titulinis miestai
  '619606': 'DLT_Titulinis_1200x250_Generic--miestai',
  '619607': 'DLT_Titulinis_300x600_01_Generic--miestai',

  // desktop delfi.lt titulinis sveikata
  '521837': 'DLT_Titulinis_1200x250_Generic--sveikata',
  '521838': 'DLT_Titulinis_300x600_01_Generic--sveikata',

  // desktop delfi.lt titulinis bustas
  '521951': 'DLT_Titulinis_1200x250_Generic--bustas',
  '521952': 'DLT_Titulinis_300x600_01_Generic--bustas',

  // desktop delfi.lt titulinis rinkis gyvenima
  '737719': 'DLT_Titulinis_300x250_01_Generic--rinkisgyvenima',

  // desktop delfi.lt titulinis studijos
  '561720': 'DLT_Titulinis_300x250_01_Generic--studijos',

  // desktop delfi.lt titulinis grilio gidas
  '558606': 'DLT_Titulinis_300x250_01_Generic--griliogidas',

  // desktop delfi.lt titulinis grynas
  '522050': 'DLT_Titulinis_1200x250_Generic--grynas',
  '522051': 'DLT_Titulinis_300x600_01_Generic--grynas',

  // desktop delfi.lt titulinis zinios issipildo
  '827594': 'DLT_Titulinis_300x250_01_Generic--ziniosissipildo',

  // desktop delfi.lt moterys
  '526200': 'DLT_Titulinis_1200x250_Generic--moterys',
  '526201': 'DLT_Titulinis_300x600_01_Generic--moterys',

  // desktop delfi.lt keliones
  '522017': 'DLT_Titulinis_1200x250_Generic--keliones',
  '522018': 'DLT_Titulinis_300x600_01_Generic--keliones',

  // desktop delfi.lt titulinis agro
  '520580': 'DLT_Titulinis_Agro_1200x250',
  '520581': 'DLT_Titulinis_Agro_300x600_01',

  // desktop delfi.lt titulinis verslas
  '521889': 'DLT_Titulinis_Verslas_1200x250',
  '521890': 'DLT_Titulinis_Verslas_300x600_01',
  '521891': 'DLT_Titulinis_Verslas_300x600_02',
  '521968': 'DLT_Titulinis_Verslas_300x250_01',

  // desktop delfi.lt titulinis gyvenimas
  '522033': 'DLT_Titulinis_Gyvenimas_1200x250',
  '522034': 'DLT_Titulinis_Gyvenimas_300x600_01',
  '522036': 'DLT_Titulinis_Gyvenimas_300x250_01',
  '522115': 'DLT_Titulinis_Gyvenimas_300x250_02',
  '522126': 'DLT_Titulinis_Gyvenimas_300x250_03',
  '522127': 'DLT_Titulinis_Gyvenimas_300x250_04',

  // desktop delfi.lt titulinis sportas
  '521992': 'DLT_Titulinis_Sportas_1200x250',
  '521993': 'DLT_Titulinis_Sportas_300x600_01',
  '521995': 'DLT_Titulinis_Sportas_300x250_01',

  // desktop delfi.lt titulinis veidai
  '522020': 'DLT_Titulinis_Veidai_1200x250',
  '522021': 'DLT_Titulinis_Veidai_300x600_01',
  '522022': 'DLT_Titulinis_Veidai_300x250_01',
  '522111': 'DLT_Titulinis_Veidai_300x250_02',
  '522113': 'DLT_Titulinis_Veidai_300x250_03',
  '522114': 'DLT_Titulinis_Veidai_300x250_04',

  // desktop delfi.lt titulinis letena
  '522093': 'DLT_Titulinis_Letena_300x250_01',

  // desktop delfi.lt titulinis autonaujienos
  '521975': 'DLT_Titulinis_Auto_1200x250',
  '521976': 'DLT_Titulinis_Auto_300x600_01',

  // desktop delfi.lt titulinis nba
  '521988': 'DLT_Titulinis_NBA_1200x250',
  '521989': 'DLT_Titulinis_NBA_300x600_01',

  // desktop delfi.lt titulinis orai
  '522095': 'DLT_Titulinis_Orai_300x250_01',

  // desktop delfi.lt titulinis maistas
  '521953': 'DLT_Titulinis_1200x250_Generic--maistas',
  '521954': 'DLT_Titulinis_300x600_01_Generic--maistas',

  // desktop delfi.lt titulinis fotogalerijos
  '521960': 'DLT_Titulinis_Fotogalerijos_1200x250',
  '521961': 'DLT_Titulinis_Fotogalerijos_300x600_01',

  // desktop delfi.lt titulinis kriminalai
  '521963': 'DLT_Titulinis_Kriminalai_1200x250',
  '521964': 'DLT_Titulinis_Kriminalai_300x600_01',

  // desktop delfi.lt titulinis stilius
  '521969': 'DLT_Titulinis_Stilius_1200x250',
  '521970': 'DLT_Titulinis_Stilius_300x600_01',

  // desktop delfi.lt titulinis mokslas it
  '521971': 'DLT_Titulinis_MokslasIT_1200x250',
  '521972': 'DLT_Titulinis_MokslasIT_300x600_01',

  // desktop delfi.lt titulinis pilietis
  '522046': 'DLT_Titulinis_Pilietis_1200x250',
  '522048': 'DLT_Titulinis_Pilietis_300x600_01',

  // desktop delfi.lt titulinus vaikai
  '538055': 'DLT_Titulinis_Vaikai_1200x250',
  '548948': 'DLT_Titulinis_Vaikai_300x600_01',

  // desktop delfi.lt titulinis bloomber
  '605489': 'DLT_Titulinis_Bloomberg_300x250_01',

  // desktop delfi.lt kultura
  '607126': 'DLT_Titulinis_Kultura_300x250_01',

  // desktop delfi.lt titulinis darbas
  '609767': 'DLT_Titulinis_Darbas_1200x250',
  '609768': 'DLT_Titulinis_Darbas_300x600_01',

  // desktop delfi.lt infoblokai
  '522142': 'DLT_Infoblokas_300x600_01',
  '522307': 'DLT_Infoblokas_300x600_02',
  '522308': 'DLT_Infoblokas_300x600_03',
  '522309': 'DLT_Infoblokas_300x600_04',
  '522310': 'DLT_Infoblokas_300x600_05',
  '522311': 'DLT_Infoblokas_300x600_06',
  '522312': 'DLT_Infoblokas_300x600_07',

  '521601': 'DLT_Titulinis_880x250_01',
  '521604': 'DLT_Titulinis_880x250_02',

  // desktop delfi.lt naujausios, lietuvoje, orai, uzsienyje, horoskopai, uzsienyje
  // jaunimas, nuomoniu ringas, teise, medijos karas propaganda, partnerio turinys
  // demaskuok, temos
  '521404': 'DLT_Pixel',
  '521398': 'DLT_1200x250',
  '521400': 'DLT_300x600_01',
  '521401': 'DLT_300x600_02',

  // desktop delfi.lt sportas
  '521459': 'DLT_Pixel',
  '521453': 'DLT_1200x250',
  '521455': 'DLT_300x600_01',
  '521456': 'DLT_300x600_02',

  // desktop delfi.lt kriminalai
  '521398-kriminalai': 'DLT_Kriminalai_1200x250',
  '521400-kriminalai': 'DLT_Kriminalai_300x600_01',
  '521401-kriminalai': 'DLT_Kriminalai_300x600_02',

  // desktop delfi.lt infoblokai kriminalai
  '522142-kriminalai': 'DLT_Kriminalai_Infoblokas_300x600_01',
  '522307-kriminalai': 'DLT_Kriminalai_Infoblokas_300x600_02',
  '522308-kriminalai': 'DLT_Kriminalai_Infoblokas_300x600_03',
  '522309-kriminalai': 'DLT_Kriminalai_Infoblokas_300x600_04',
  '522310-kriminalai': 'DLT_Kriminalai_Infoblokas_300x600_05',
  '522311-kriminalai': 'DLT_Kriminalai_Infoblokas_300x600_06',
  '522312-kriminalai': 'DLT_Kriminalai_Infoblokas_300x600_07',

  // desktop delfi.lt veidai
  '521467': 'DLT_Pixel',
  '521461': 'DLT_1200x250',
  '521463': 'DLT_300x600_01',
  '521464': 'DLT_300x600_02',

  // desktop delfi.lt gyvenimas
  '521477': 'DLT_Pixel',
  '521471': 'DLT_1200x250',
  '521473': 'DLT_300x600_01',
  '521474': 'DLT_300x600_02',

  // desktop delfi.lt mokslas
  '521418': 'DLT_Pixel',
  '521412': 'DLT_1200x250',
  '521414': 'DLT_300x600_01',
  '521415': 'DLT_300x600_02',

  // desktop delfi.lt verslas
  '521411': 'DLT_Pixel',
  '521405': 'DLT_1200x250',
  '521407': 'DLT_300x600_01',
  '521408': 'DLT_300x600_02',

  // desktop delfi.lt sveikata
  '521535': 'DLT_Pixel',
  '521529': 'DLT_1200x250',
  '521531': 'DLT_300x600_01',
  '521532': 'DLT_300x600_02',
  '541298': 'DLT_300x600_03',
  '559573': 'DLT_300x600_04',

  // desktop delfi.lt kultura
  '644625': 'DLT_Pixel',
  '644618': 'DLT_1200x250',
  '644620': 'DLT_300x600_01',
  '644621': 'DLT_300x600_02',

  // desktop delfi.lt auto
  '521448': 'DLT_Pixel',
  '521442': 'DLT_1200x250',
  '521444': 'DLT_300x600_01',
  '521445': 'DLT_300x600_02',

  // desktop delfi.lt pilietis
  '521491': 'DLT_Pixel',
  '521485': 'DLT_1200x250',
  '521486': 'DLT_300x600_Kampas',
  '521487': 'DLT_300x600_01',
  '521488': 'DLT_300x600_02',

  // desktop delfi.lt agro (gyvas-kaimas)
  '521555': 'DLT_Pixel',
  '521549': 'DLT_1200x250',
  '521551': 'DLT_300x600_01',
  '521552': 'DLT_300x600_02',

  // delfi.lt letena
  '648058': 'DLT_Pixel',
  '534338': 'DLT_1200x250',
  '550753': 'DLT_300x600_01',
  '586985': 'DLT_300x600_02',
  '648055': 'DLT_300x250_InArticle_1',
  '648056': 'DLT_300x250_InArticle_2',
  '648057': 'DLT_300x250_InArticle_3',

  // m.delfi.lt pixel
  '550706': 'DLT_Mobile_320x50',

  // m.delfi.lt boredpanda pixel
  '550706-boredpanda': 'DLT_Boredpanda_Mobile_320x50',

  // delfi.lt projektai/atsakingas-poziuris ???
  '805138': 'DLT_300x600_01',
  '805140': 'DLT_300x600_02',
  '805145': 'DLT_300x250_InArticle_1',
  '805148': 'DLT_300x250_InArticle_2',

  '833591': 'DLT_1200x250',
  '833595': 'DLT_Pixel',

  // 01. Delfi TV 1200x250
  '522316': 'DLT_TV_1200x250_01',
  '525842': 'DLT_TV_300x250_01',

  // 01. Delfi.EN 1200x250
  '522353': 'DLT_1200x250',

  // 12. Bendrinis 300x600 Fotogalerijos
  '525839': 'DLT_300x600_Gallery',

  // titulinis
  '928806': 'DLT_Titulinis_880x400',
  '928810': 'DLT_Titulinis_mobile_300x250_plius',
  '807136': 'DLT_Titulinis_Mobile_300x250_Rekomenduojame',
  '922720': 'DLT_Titulinis_Mobile_300x250_Uzsienyje',

  // delfi.lt receptai
  '925755': 'DLT_1200x250',
  '925754': 'DLT_300x600_01',
  '925756': 'DLT_300x600_02',
  '925759': 'DLT_300x600_03',

  '608944': 'DLT_Infoblokas_300x600_08',

  // visi keliai
  '522101': 'DLT_1200x250',
  '522102': 'DLT_300x600_01',
  '522103': 'DLT_300x600_02',
  '526087': 'DLT_300x600_03',
  '522104': 'DLT_300x250_InArticle_1',
  '522106': 'DLT_300x250_InArticle_2',
  '522107': 'DLT_Pixel',

  // visi keliai titulinis
  '1206544': 'DLT_Titulinis_1200x250_Generic--visikeliai',
  '1206546': 'DLT_Titulinis_300x600_01_Generic--visikeliai',
  '1206560': 'DLT_Titulinis_Mobile_300x250_special--visikeliai',

  // RU Mobile
  '1399928': 'DLT_RU_Mobile_300x250_1',
  '1399929': 'DLT_RU_Mobile_300x250_2',
  '1399930': 'DLT_RU_Mobile_300x250_3',
  '1399931': 'DLT_RU_Mobile_300x250_4',
  '1399932': 'DLT_RU_Mobile_300x250_5',
  '1399933': 'DLT_RU_Mobile_300x250_6',
  '1399934': 'DLT_RU_Mobile_300x250_7',

  // RU TV
  '1402914': 'DLT_RU_TV_1200x250',
  '1402915': 'DLT_RU_TV_300x250',

  // '665313': 'DLT_580x200'

  // DLFI PLIUS Desktop
  '1522755': 'DLT_Desktop_Paid_1200x250',
  '1522780': 'DLT_Desktop_Paid_1200x250',
  '1522889': 'DLT_Desktop_Paid_300x600_corner',
  '1522834': 'DLT_Desktop_Paid_300x600_1',
  '1522857': 'DLT_Desktop_Paid_300x600_2',
  '1522858': 'DLT_Desktop_Paid_300x600_3',
  '1522860': 'DLT_Desktop_Paid_300x600_4',
  '1522862': 'DLT_Desktop_Paid_InArticle_300x250_1',
  '1522886': 'DLT_Desktop_Paid_InArticle_300x250_2',
  '1522887': 'DLT_Desktop_Paid_InArticle_300x250_3',
  '1522888': 'DLT_Desktop_Paid_InArticle_300x250_4',

  // DELFI PLIUS Mobile
  '1522920': 'DLT_Mobile_Paid_300x250_1',
  '1522921': 'DLT_Mobile_Paid_300x250_2',
  '1522922': 'DLT_Mobile_Paid_300x250_3',
  '1522948': 'DLT_Mobile_Paid_300x250_4',
  '1522951': 'DLT_Mobile_Paid_300x250_5',
  '1522954': 'DLT_Mobile_Paid_300x250_6',
  '1522955': 'DLT_Mobile_Paid_300x250_7',

  // VERSLAS PLIUS
  '1522781': 'DLT_Desktop_Paid_1200x250',
  '1522782': 'DLT_Desktop_Paid_1200x250',
  '1317127': 'DLT_Desktop_Paid_300x600_1',
  '1522914': 'DLT_Desktop_Paid_InArticle_300x250_1',
  '1522915': 'DLT_Desktop_Paid_InArticle_300x250_2',
  '1522916': 'DLT_Desktop_Paid_InArticle_300x250_3',
  '1522917': 'DLT_Desktop_Paid_InArticle_300x250_4',

  // VERSLAS PLIUS MOBILE
  '1522923': 'DLT_Mobile_Paid_300x250_1',
  '1522924': 'DLT_Mobile_Paid_300x250_2',
  '1522925': 'DLT_Mobile_Paid_300x250_3',
  '1522949': 'DLT_Mobile_Paid_300x250_4',
  '1522950': 'DLT_Mobile_Paid_300x250_5',
  '1522952': 'DLT_Mobile_Paid_300x250_6',
  '1522953': 'DLT_Mobile_Paid_300x250_7',

  // M360
  '1229350': 'DLT_Desktop_Paid_1200x250',
  '1229364': 'DLT_Desktop_Paid_1200x250',
  '1229365': 'DLT_Desktop_Paid_300x600_1',
  '1229366': 'DLT_Desktop_Paid_InArticle_300x250_1',
  '1229367': 'DLT_Desktop_Paid_InArticle_300x250_2',

  // M360 Mobile
  '1229368': 'DLT_Mobile_Paid_300x250_1',
  '1229369': 'DLT_Mobile_Paid_300x250_2',
  '1229370': 'DLT_Mobile_Paid_300x250_3',
  '1229371': 'DLT_Mobile_Paid_300x250_4',
  '1229372': 'DLT_Mobile_Paid_300x250_5',
  '1229373': 'DLT_Mobile_Paid_300x250_6',
  '1229374': 'DLT_Mobile_Paid_300x250_7',

  // no sub
  '1535349': 'DLT_Mobile_Paid_300x250_1_NoSub',
  '1535351': 'DLT_Mobile_Paid_300x250_2_NoSub',

  // 2023-02-23
  '1636069': 'DLT_Infoblokas_300x600_01', // 01. Aktualijos Infoblokas 300x600 (1)
  '1636070': 'DLT_Infoblokas_300x600_02', // 02. Aktualijos Infoblokas 300x600 (2)
  '1636071': 'DLT_Infoblokas_300x600_03', // 03. Aktualijos Infoblokas 300x600 (3)
  '1636072': 'DLT_Infoblokas_300x600_04', // 04. Aktualijos Infoblokas 300x600 (4)
  '1636073': 'DLT_Infoblokas_300x600_05', // 05. Aktualijos Infoblokas 300x600 (5)
  '1636074': 'DLT_Infoblokas_300x600_06', // 06. Aktualijos Infoblokas 300x600 (6)
  '1636075': 'DLT_Infoblokas_300x600_07', // 07. Aktualijos Infoblokas 300x600 (7)
  '1636080': 'DLT_Infoblokas_300x600_01', // 08. Verslas Infoblokas 300x600 (1)
  '1636081': 'DLT_Infoblokas_300x600_02', // 09. Verslas Infoblokas 300x600 (2)
  '1636082': 'DLT_Infoblokas_300x600_03', // 10. Verslas Infoblokas 300x600 (3)
  '1636083': 'DLT_Infoblokas_300x600_04', // 11. Verslas Infoblokas 300x600 (4)
  '1636084': 'DLT_Infoblokas_300x600_05', // 12. Verslas Infoblokas 300x600 (5)
  '1636085': 'DLT_Infoblokas_300x600_06', // 13. Verslas Infoblokas 300x600 (6)
  '1636086': 'DLT_Infoblokas_300x600_07', // 14. Verslas Infoblokas 300x600 (7)
  '1636091': 'DLT_Infoblokas_300x600_01', // 15. Mokslas Infoblokas 300x600 (1)
  '1636092': 'DLT_Infoblokas_300x600_02', // 16. Mokslas Infoblokas 300x600 (2)
  '1636093': 'DLT_Infoblokas_300x600_03', // 17. Mokslas Infoblokas 300x600 (3)
  '1636094': 'DLT_Infoblokas_300x600_04', // 18. Mokslas Infoblokas 300x600 (4)
  '1636095': 'DLT_Infoblokas_300x600_05', // 19. Mokslas Infoblokas 300x600 (5)
  '1636096': 'DLT_Infoblokas_300x600_06', // 20. Mokslas Infoblokas 300x600 (6)
  '1636097': 'DLT_Infoblokas_300x600_07', // 21. Mokslas Infoblokas 300x600 (7)
  '1636142': 'DLT_Infoblokas_300x600_01', // 22. Veidai Infoblokas 300x600 (1)
  '1636143': 'DLT_Infoblokas_300x600_02', // 23. Veidai Infoblokas 300x600 (2)
  '1636144': 'DLT_Infoblokas_300x600_03', // 24. Veidai Infoblokas 300x600 (3)
  '1636145': 'DLT_Infoblokas_300x600_04', // 25. Veidai Infoblokas 300x600 (4)
  '1636136': 'DLT_Infoblokas_300x600_05', // 26. Veidai Infoblokas 300x600 (5)
  '1636137': 'DLT_Infoblokas_300x600_06', // 27. Veidai Infoblokas 300x600 (6)
  '1636138': 'DLT_Infoblokas_300x600_07', // 28. Veidai Infoblokas 300x600 (7)
  '1636103': 'DLT_Infoblokas_300x600_01', // 29. Auto Infoblokas 300x600 (1)
  '1636104': 'DLT_Infoblokas_300x600_02', // 30. Auto Infoblokas 300x600 (2)
  '1636105': 'DLT_Infoblokas_300x600_03', // 31. Auto Infoblokas 300x600 (3)
  '1636106': 'DLT_Infoblokas_300x600_04', // 32. Auto Infoblokas 300x600 (4)
  '1636107': 'DLT_Infoblokas_300x600_05', // 33. Auto Infoblokas 300x600 (5)
  '1636108': 'DLT_Infoblokas_300x600_06', // 34. Auto Infoblokas 300x600 (6)
  '1636109': 'DLT_Infoblokas_300x600_07', // 35. Auto Infoblokas 300x600 (7)
  '1636132': 'DLT_Infoblokas_300x600_01', // 36. Sportas Infoblokas 300x600 (1)
  '1636133': 'DLT_Infoblokas_300x600_02', // 37. Sportas Infoblokas 300x600 (2)
  '1636134': 'DLT_Infoblokas_300x600_03', // 38. Sportas Infoblokas 300x600 (3)
  '1636135': 'DLT_Infoblokas_300x600_04', // 39. Sportas Infoblokas 300x600 (4)
  '1636146': 'DLT_Infoblokas_300x600_05', // 40. Sportas Infoblokas 300x600 (5)
  '1636147': 'DLT_Infoblokas_300x600_06', // 41. Sportas Infoblokas 300x600 (6)
  '1636148': 'DLT_Infoblokas_300x600_07', // 42. Sportas Infoblokas 300x600 (7)
  '1636164': 'DLT_Infoblokas_300x600_01', // 43. Orai Infoblokas 300x600 (1)
  '1636165': 'DLT_Infoblokas_300x600_02', // 44. Orai Infoblokas 300x600 (2)
  '1636166': 'DLT_Infoblokas_300x600_03', // 45. Orai Infoblokas 300x600 (3)
  '1636167': 'DLT_Infoblokas_300x600_04', // 46. Orai Infoblokas 300x600 (4)
  '1636168': 'DLT_Infoblokas_300x600_05', // 47. Orai Infoblokas 300x600 (5)
  '1636169': 'DLT_Infoblokas_300x600_06', // 48. Orai Infoblokas 300x600 (6)
  '1636170': 'DLT_Infoblokas_300x600_07', // 49. Orai Infoblokas 300x600 (7)
  '1636176': 'DLT_Infoblokas_300x600_01', // 50. Gyvenimas Infoblokas 300x600 (1)
  '1636177': 'DLT_Infoblokas_300x600_02', // 51. Gyvenimas Infoblokas 300x600 (2)
  '1636178': 'DLT_Infoblokas_300x600_03', // 52. Gyvenimas Infoblokas 300x600 (3)
  '1636179': 'DLT_Infoblokas_300x600_04', // 53. Gyvenimas Infoblokas 300x600 (4)
  '1636180': 'DLT_Infoblokas_300x600_05', // 54. Gyvenimas Infoblokas 300x600 (5)
  '1636181': 'DLT_Infoblokas_300x600_06', // 55. Gyvenimas Infoblokas 300x600 (6)
  '1636182': 'DLT_Infoblokas_300x600_07', // 56. Gyvenimas Infoblokas 300x600 (7)
  '1636188': 'DLT_Infoblokas_300x600_01', // 57. Pilietis Infoblokas 300x600 (1)
  '1636189': 'DLT_Infoblokas_300x600_02', // 58. Pilietis Infoblokas 300x600 (2)
  '1636190': 'DLT_Infoblokas_300x600_03', // 59. Pilietis Infoblokas 300x600 (3)
  '1636191': 'DLT_Infoblokas_300x600_04', // 60. Pilietis Infoblokas 300x600 (4)
  '1636192': 'DLT_Infoblokas_300x600_05', // 61. Pilietis Infoblokas 300x600 (5)
  '1636193': 'DLT_Infoblokas_300x600_06', // 62. Pilietis Infoblokas 300x600 (6)
  '1636194': 'DLT_Infoblokas_300x600_07', // 63. Pilietis Infoblokas 300x600 (7)

  '1321185': 'DLT_Titulinis_1200x250_Generic', // Titulinis/LOGIN.lt 1200x250 Delfi
  '1577788': 'DLT_Titulinis_300x600_01_Generic', // Titulinis/LOGIN.lt 300x600 Delfi
  '1559615': 'DLT_Titulinis_1200x250_Generic', // xxx_Titulinis/SuTAUPYK 1200x250
  '1559618': 'DLT_Titulinis_300x600_01_Generic', // xxx_Titulinis/SUTAUPYK 300x600

  '526330': 'DLT_Outstream_Deal', // outstream Veidai
  '526284': 'DLT_Outstream_Deal', // outstream Pilietis
  '526327': 'DLT_Outstream_Deal', // outstream Gyvenimas
} as Record<string, string>;
