// NB! When adding new specialFeatures it's important to make sure that varnish cache
// between different applications wont cause vue hydration errors

export default {
  main_portal_url: 'https://www.delfi.lt',
  main_slug: 'news',
  old_type_redirects: true,
  social_share: {
    facebook: 'https://www.facebook.com/sharer/sharer.php',
    linkedin: 'https://www.linkedin.com/shareArticle',
    messenger: 'https://www.facebook.com/dialog/send',
    twitter: 'https://twitter.com/share',
  },
  player_url: 'https://tasku.delfi.ee/kuula',
  s2f_url: 'https://www.delfi.lt/s2f.php',
  disableComments: false,
  disableLogin: false,
  napo_url: 'https://www.napo.lt/napo.js?id=DELFI',
  privacy_policy_url: 'https://www.delfi.lt/apie/?page=privatumas',
  applications: [
    {
      type: 'NativeAppIOS',
      ua: /(DelfiEE|DelfiLT|DelfiLV)(www)\/(\d+).*(ipad|iphone|ipod)/gi,
      signature: false,
      specialFeatures: {
        forceArticleRefetch: true,
        adFree: false,
        disableHeader: false,
        disableConsentModal: false,
        hideSignupAndOrders: {
          enabled: true,
          affectedApps: [{ version: '619' }],
        },
      },
    },
    {
      type: 'NativeAppIOS',
      ua: /(DelfiEE|DelfiLT|DelfiLV)(rudelfi)\/(\d+).*(ipad|iphone|ipod)/gi,
      signature: false,
      specialFeatures: {
        forceArticleRefetch: true,
        adFree: false,
        disableHeader: false,
        disableConsentModal: false,
        hideSignupAndOrders: {
          enabled: true,
          affectedApps: [{ version: '619' }],
        },
      },
    },
    {
      type: 'NativeAppAndroid',
      ua: /(DelfiEE|DelfiLT|DelfiLV)([a-zA-Z]+)\/(\d+).*(android)/gi,
      signature: false,
      specialFeatures: {
        forceArticleRefetch: false,
        adFree: false,
        disableHeader: false,
        disableConsentModal: false,
        hideSignupAndOrders: {
          enabled: false,
          affectedApps: null,
        },
      },
    },
  ],
  searchBotsRegex: 'google|rahvusraamatukogu|cxensebot|bingbot|bingpreview',
  readAccessIps: ['193.40.148.68', '213.219.101.94', '193.40.148.66'],
  adBlockVideosIds: {
    default: {
      adBlock: 'f9b4bf60-a0c0-11ec-a2af-512016e225b7',
      adBlockPlus: 'f9c1dec0-a0c0-11ec-afdb-a9205bbd5cc5',
    },
    ru: {
      adBlock: 'f9b4e670-a0c0-11ec-a2af-512016e225b7',
      adBlockPlus: 'f9c0f460-a0c0-11ec-afdb-a9205bbd5cc5',
    },
  },
  sentryIgnoredCodes: [404],
};

export interface RootConfig {
  main_portal_url: string;
  main_slug: string;
  old_type_redirects: boolean;
  social_share: {
    facebook: string;
    twitter: string;
  };
  player_url: string;
  disableComments: boolean;
  disableLogin: boolean;
  adBlockVideosIds: Record<
    string,
    {
      adBlock: string;
      adBlockPlus: string;
    }
  >;
}
