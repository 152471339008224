export default [
  '526335',
  '526333',
  '713023',
  '526327',
  '526334',
  '713015',
  '526284',
  '799824',
  '667366',
  '526332',
  '669807',
  '526330',
  '554387',
  '614087',
  '619603',
  '526344',
  '1596415',
  '818852',
  '525390',
  '941176',
  '687359',
  '687360',
  '1253753',
  '525391',
  '525392',
  '525393',
  '1399936',
  '457548',
  '522107',
  '609556',
  '521555',
  '521404',
  '521448',
  '581219',
  '521517',
  '521477',
  '521588',
  '644625',
  '521543',
  '619600',
  '521418',
  '526190',
  '521491',
  '528837',
  '1142487',
  '538069',
  '521459',
  '521579',
  '521467',
  '521411',
  '648058',
  '521535',
  '558981',
  '521566',
  '522135',
  '1629763',
  '550706',
  '1399935',
  '1629770',
  '1544006',
  '1544005',
  'DLT_Mobile_Pixel_Poststitial',
  '1414436',
  '1831691',
  '833595',
  // Promotion line ads
  '1024268',
  '1024269',
] as string[];
