import { Plugin, Context } from '@nuxt/types';
import { cXense } from '@analytics/trackers';
import { Data as ArticleData } from '@root/modules/article/types/article';
import cXensePageType from '@root/modules/analytics/config/cxense/cXensePageType.config';
import events from '../config/cxense';
import interpolate from '@root/common/utils/interpolate';
import getMobileApplication from '../utils/getMobileApplication';
import { CxenseEvent } from '@root/modules/analytics/types/analytics';
import getEventByKey from '../utils/getEventByKey';
import getDelfiHashTracker from '../utils/getDelfiHashTracker';

type CustomParameters = Record<string, unknown>;

const cXensePlugin: Plugin = async (ctx) => {
  const { app, store, route } = ctx;
  const analytics = app.$channelConfig('analytics');
  const { id, locale } = app.$channelConfig('settings');

  if (!analytics?.cxense?.siteId) {
    return;
  }

  const cX = new cXense();
  await cX.init({ uid: analytics.cxense.siteId });

  cX.push('cint', '224');
  cX.push('sync', 'adform');

  const pageCustomParameters = () => {
    const routeName = app.router?.currentRoute.name;
    const hash = window.location.hash;
    const customParameters: CustomParameters = {};
    customParameters.channel = id;

    const hashParameter = getDelfiHashTracker(hash);
    if (hashParameter) {
      customParameters.delfi_link = hashParameter;
    }

    customParameters.platform = 'Online vaade';
    customParameters.page_type = cXensePageType[routeName || 'other'] || cXensePageType['other'];
    customParameters.user_logged_in = store.state.piano.isLoggedIn ? 'yes' : 'no';
    customParameters.reg_user_dplus = store.state.piano.access.channelAccess;
    customParameters.ad_block = store.state.adForm.enableAdBlockLayer;

    const application = getMobileApplication(window.navigator.userAgent);
    if (application) {
      customParameters.browser_container = application;
    }

    if (store.state.piano.profile) {
      customParameters.userId = store.state.piano.profile?.uid;
    }

    return customParameters;
  };

  const cXensePageView = (route: Context['route']) => {
    const isArticle = route.name === 'article';

    // Send page view of all pages except article
    if (isArticle) {
      return false;
    }

    const customParameters = pageCustomParameters();

    cX.pageView({}, customParameters);
  };

  app?.router?.afterEach((to) => {
    cXensePageView(to);
  });

  // Get cXense segments and share them to trackers
  window.addEventListener('load', async () => {
    const userId = await cX.getUserId();
    cX.push('getUserSegmentIds', {
      persistedQueryId: 'b04a959c2ea64c1ef725a6abb04ccecbbd9e6671',
      callback: async (segmentIds: string[]) => {
        store.commit('analytics/setClickEvent', {
          facebookPixel: {
            eventName: 'SYNC_SEGMENTS',
            eventData: { eventType: 'sync', data: { type: 'trackCustom', name: 'CxSegments', segmentIds } },
          },
          getSiteControl: {
            eventName: 'SYNC_SEGMENTS',
            eventData: { eventType: 'sync', data: { segmentIds, userId } },
          },
        });
      },
    });
  });

  const cXenseArticlePageView = (activeArticle: ArticleData) => {
    const { channelAccess } = store.state.piano.access;

    let customParameters = pageCustomParameters();
    const articleCustomParameters: CustomParameters = {
      is_paid_article: activeArticle.content.paywall.enabled,
      is_paid_article_and_have_paid: activeArticle.content.paywall.enabled && channelAccess,
    };

    customParameters = { ...customParameters, ...articleCustomParameters };
    cX.pageView({}, customParameters);
  };

  // Watch activeArticle store state to send article page view
  const startWatchingAccess = () => {
    store.watch(
      (state) => state.piano.access,
      async () => {
        if (store.state.article.activeArticle) {
          const cXenseArticle = { ...store.state.article.activeArticle };
          cXenseArticlePageView(cXenseArticle);
        } else {
          cXensePageView(route);
        }
      }
    );
  };

  store.watch(
    (state) => state.article.activeArticlePath,
    async (activeArticlePath) => {
      if (activeArticlePath && store.state.article.activeArticle) {
        const cXenseArticle = { ...store.state.article.activeArticle };
        cXenseArticlePageView(cXenseArticle);
      }
    }
  );

  if (store.state.piano.isScriptInited) {
    if (store.state.article.activeArticle) {
      const cXenseArticle = { ...store.state.article.activeArticle };
      cXenseArticlePageView(cXenseArticle);
    } else {
      cXensePageView(route);
    }
    startWatchingAccess();
  } else {
    store.watch(
      (state) => state.piano.isScriptInited,
      async () => {
        if (store.state.article.activeArticle) {
          const cXenseArticle = { ...store.state.article.activeArticle };
          cXenseArticlePageView(cXenseArticle);
        } else {
          cXensePageView(route);
        }
        startWatchingAccess();
      }
    );
  }

  // Watch analytics store eventName change
  store.watch(
    (state) => state.analytics.tracker.cXense.clickEventName,
    async (clickEventName) => {
      if (!clickEventName) {
        return;
      }
      const event = getEventByKey<CxenseEvent>(events, clickEventName, locale);

      if (event) {
        if (!store.state.analytics.tracker.cXense.clickEventData) {
          return false;
        }

        const { type, data } = store.state.analytics.tracker.cXense.clickEventData;
        const eventData = event.types[type];
        const { eventType, customParams, options } = eventData;
        const eventCustomParams = customParams ? JSON.parse(interpolate(customParams, { data, app })) : '';
        cX.sendEvent(eventType, eventCustomParams, options ? JSON.parse(options) : null);
      }
      store.commit('analytics/setClickEvent', { cXense: { eventName: null, eventData: null } });
    }
  );

  // Log sponsored content articles in view
  if (process.client && 'IntersectionObserver' in window && route.name === 'frontpage') {
    const elemsToObserve = document.querySelectorAll('.pt-content');
    const ptObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          const element = entry.target as unknown as HTMLElement;
          const articleUrl = element.querySelector('a')?.href as string;
          const urlParts = articleUrl.split('-') as string[];
          const articleId = urlParts[urlParts?.length - 1];

          cX.sendEvent(
            'article_visible',
            { article_id: articleId, article_url: articleUrl },
            { origin: 'lth-delfi', persistedQueryId: '60134f11dac530e2cd5c308a42f829d82289a3b8' }
          );

          ptObserver.unobserve(entry.target);
        }
      });
    }, {});

    elemsToObserve.forEach((elemToObserve) => {
      ptObserver.observe(elemToObserve);
    });

    const lazySections = document.querySelectorAll('.section-lazy');
    const lazyObserver = new MutationObserver((mutations) => {
      mutations.forEach(function (mutation) {
        if (mutation.type === 'childList') {
          Array.prototype.filter.call(mutation.addedNodes, (node) => {
            if (node.childNodes.length > 0) {
              const elemsToObserve = node.querySelector('.pt-content');
              if (elemsToObserve) {
                const elemsToObserve: Element[] = node.querySelectorAll('.pt-content');
                elemsToObserve.forEach((elemToObserve) => {
                  ptObserver.observe(elemToObserve);
                });
              }
            }
          });
        }
      });
    });

    lazySections.forEach((elemToObserve) => {
      lazyObserver.observe(elemToObserve, { childList: true, subtree: true });
    });
  }

  // Log frontpage sections visible event (scroll depth)
  if (process.client && 'IntersectionObserver' in window && route.name === 'frontpage' && route.path === '/') {
    const elemsToObserve = document.querySelectorAll('section');
    const sectionsObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          const element = entry.target as unknown as HTMLElement;
          const sectionIndex = element.dataset.sectionIndex;
          const title = element.querySelector('.block-type-8');
          const heading = title?.querySelector('.block-type-8__title');
          const sectionName = heading ? (heading as HTMLElement).innerText : null;

          if (sectionName) {
            cX.sendEvent(
              'section_visible',
              { section: `${sectionIndex}. ${sectionName}`, sectionName, sectionIndex },
              { origin: 'lth-delfi', persistedQueryId: '60134f11dac530e2cd5c308a42f829d82289a3b8' }
            );
          }

          sectionsObserver.unobserve(entry.target);
        }
      });
    }, {});

    elemsToObserve.forEach((elemToObserve) => {
      sectionsObserver.observe(elemToObserve);
    });

    const lazyObserver = new MutationObserver((mutations) => {
      mutations.forEach(function (mutation) {
        if (mutation.type === 'childList') {
          Array.prototype.filter.call(mutation.addedNodes, (node) => {
            if (node.childNodes.length > 0 && node.nodeName === 'DIV' && node.classList.contains('row')) {
              const row = node as HTMLElement;
              const section = row.querySelector('section');
              if (section) {
                sectionsObserver.observe(section);
              }
            }
          });
        }
      });
    });

    const container = document.querySelectorAll('.container.default')[0];
    lazyObserver.observe(container, { childList: true, subtree: true });
  }
};

export default cXensePlugin;
