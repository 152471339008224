import config from '@media/config/media.config';
import adScheduleConfig from '@media/config/videoPlayerAdSchedule.config';
import stringTemplate from '@ekd/js-stringtemplate';
import { AdScheduleSettings, AdSchedule } from '@media/types/PlayerAdSchedule';
import getRootConfig from '@media/utils/getRootConfig';
import { gridConfig } from '@core/config/grid.config';

type vast = 'stream' | 'default' | 'special' | 'audio';

interface $nuxtWindow extends Window {
  $nuxt: any;
}

declare let window: $nuxtWindow;
export interface AdsData {
  adFree?: boolean;
  keywords?: string[];
  ppid?: string;
}

type VastIdsType = Record<string, number | number[]>;

const { minVideoDurationForMidRollAd, subscriberDisabledAds, adOffsets } = adScheduleConfig;

const getPlayerAdSchedule = (type: string, adsData?: AdsData, isSubscribed?: boolean, videoDurationSeconds?: number): AdScheduleSettings => {
  const { ads } = config;
  const vastIds = ads.ids[type as vast] || ads.ids.default;
  const schedule = buildAdSchedule(vastIds, isSubscribed, adsData, videoDurationSeconds);
  const adsBids = ads.bids;

  const adScheduleWithSettings = {
    client: 'vast',
    autoplayadsmuted: true,
    preloadAds: true,
    schedule,
    bids: adsBids,
  };

  return adScheduleWithSettings;
};

function buildAdSchedule(vastIds: VastIdsType, isSubscribed?: boolean, adsData?: AdsData, videoDurationSeconds?: number): AdSchedule[] {
  const schedule: AdSchedule[] = [];
  const time = +new Date();
  let isAdsdisabledinConfig = false;

  if (typeof window !== 'undefined' && window.$nuxt && window.$nuxt.$route.params.category) {
    const categorySlug = window.$nuxt.$route.params.subcategory || window.$nuxt.$route.params.category;
    const customAdFormSettings = getRootConfig('page')?.article?.component?.adForm;
    if (customAdFormSettings) {
      if (Array.isArray(customAdFormSettings)) {
        const categoryAdsConfig = customAdFormSettings.find((item: { categories: string | string[] }) => item.categories.includes(categorySlug)) || [];
        if (categoryAdsConfig && categoryAdsConfig.disableAds && categoryAdsConfig?.disableAds.includes('videoAds')) {
          isAdsdisabledinConfig = true;
        }
      }
    }
  }

  for (const [key, value] of Object.entries(vastIds)) {
    if (isSubscribed && subscriberDisabledAds.includes(key)) {
      continue;
    }
    if (isAdsdisabledinConfig) {
      continue;
    }
    let offset: string | number = key;
    // Enable mid-roll, if video length exceeds maxVideoDuration threshold
    const midRollAdEnabled: boolean = videoDurationSeconds ? videoDurationSeconds >= minVideoDurationForMidRollAd : false;

    if (key === 'mid') {
      if (midRollAdEnabled) {
        offset = adOffsets.mid;
      } else {
        continue;
      }
    } else if (key === 'overlay') {
      const isMobileView = window && window.screen.width <= gridConfig.breakpoints.lg;
      if (isMobileView) {
        continue;
      } else {
        offset = adOffsets.overlay;
      }
    }

    // Construct ad object
    const adObject: AdSchedule = { offset };
    const mkw = (adsData?.keywords || []).join(',') || '';
    const ppid = adsData?.ppid || '';

    const tagUrlBuilder = (vastId: number | string) => stringTemplate(config.ads.vastUrl, { vastId, time, mkw, ppid });

    // Pod is used for multiple consecutive ads
    // Tag is used for a singular ad
    if (typeof value === 'object') {
      const podArray = [];
      for (const subKey in value) {
        if (isSubscribed && subscriberDisabledAds.includes(subKey)) {
          continue;
        }

        podArray.push(tagUrlBuilder(value[subKey]));
      }
      adObject.pod = podArray;
    } else {
      adObject.tag = tagUrlBuilder(value);
    }

    schedule.push(adObject);
  }

  return schedule;
}

export { getPlayerAdSchedule };
